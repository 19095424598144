import './Projects.css'
import { Button } from '@mui/material'

const Projects = () => {
  return (
    <div className="Projects" id="projects">
      <h2>Projects</h2>
      <div className="projects-container">
        {/* Jason's Records */}
        <a href="https://jasons-records.netlify.app/" target={'blank'}>
          <div className="project">
            <img src="records.jpg" alt="Jason's Records" />
            <h4>Jason's Records</h4>
            <div className="project-info">
              <p>
                A searchable list of my record collection with data from an API
              </p>
              <Button
                className="project-site-link"
                variant="contained"
                color="success"
              >
                Visit Site
              </Button>
              <div className="project-code-links">
                <a
                  href="https://github.com/jasonfrancki/jasons-records"
                  target={'blank'}
                >
                  <Button
                    className="project-site-link"
                    variant="contained"
                    color="secondary"
                  >
                    View Code
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </a>
        {/* End of Jason's Records */}

        {/* Jas 'n Save */}
        <a href="https://jasonsgroceries.netlify.app/" target={'blank'}>
          <div className="project">
            <img src="jas-n-save.png" alt="Jas 'n Save" />
            <h4>Jas 'n Save</h4>
            <div className="project-info">
              <p>
                A grocery list app to make a trip to the store more efficient
              </p>
              <Button
                className="project-site-link"
                variant="contained"
                color="success"
              >
                Visit Site
              </Button>
              <div className="project-code-links">
                <a
                  href="https://github.com/jasonfrancki/jas-n-save"
                  target={'blank'}
                >
                  <Button
                    className="project-site-link"
                    variant="contained"
                    color="secondary"
                  >
                    View Code
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </a>
        {/* End of Jason's Records */}

        {/* Michael Match */}
        <a href="https://michael-match.netlify.app" target={'blank'}>
          <div className="project">
            <img src="mikematch.png" alt="Michael Match" />
            <h4>Michael Match</h4>
            <div className="project-info">
              <p>A memory match game for fans of The Office</p>
              <Button
                className="project-site-link"
                variant="contained"
                color="success"
              >
                Visit Site
              </Button>
              <div className="project-code-links">
                <a
                  href="https://github.com/jasonfrancki/michael-match"
                  target={'blank'}
                >
                  <Button
                    className="project-site-link"
                    variant="contained"
                    color="secondary"
                  >
                    View Code
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </a>
        {/* End of Michael Match */}

        {/* NBA Scoreboard */}

        {/* <a href="https://nba-scoreboard.netlify.app/" target={"blank"}>
          <div className="project">
            <img src="nba.png" alt="NBA Scores" />
            <h4>NBA Scores</h4>
            <div className="project-info">
              <p>
                NBA Scoreboard uses an API to display scores from today or past
                days, as well as start times for future games
              </p>
              <Button
                className="project-site-link"
                variant="contained"
                color="success"
              >
                Visit Site
              </Button>
              <div className="project-code-links">
                <a
                  href="https://github.com/jasonfrancki/nba-scores"
                  target={"blank"}
                >
                  <Button
                    className="project-site-link"
                    variant="contained"
                    color="secondary"
                  >
                    View Code
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </a> */}
        {/* End of NBA Scoreboard */}
      </div>
    </div>
  )
}

export default Projects
