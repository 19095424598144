import {
  SiHtml5,
  SiCss3,
  SiJavascript,
  SiTypescript,
  SiReact,
  SiNodedotjs,
  SiGit,
  SiMysql,
} from 'react-icons/si'
import './Skills.css'

const Skills = () => {
  return (
    <div className="Skills" id="skills">
      <h2>Skills</h2>
      <div className="skills-container">
        <figure>
          <SiHtml5 />
          <figcaption>HTML</figcaption>
        </figure>
        <figure>
          <SiCss3 />
          <figcaption>CSS</figcaption>
        </figure>
        <figure>
          <SiJavascript />
          <figcaption>JavaScript</figcaption>
        </figure>
        <figure>
          <SiTypescript />
          <figcaption>TypeScript</figcaption>
        </figure>
        <figure>
          <SiReact />
          <figcaption>React</figcaption>
        </figure>
        <figure>
          <SiNodedotjs />
          <figcaption>Node.js</figcaption>
        </figure>
        <figure>
          <SiMysql />
          <figcaption>Mysql</figcaption>
        </figure>
        <figure>
          <SiGit />
          <figcaption>Git</figcaption>
        </figure>
      </div>
    </div>
  )
}

export default Skills
