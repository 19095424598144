import './Nav.css'
// import { IoColorPalette } from 'react-icons/io5'

const Nav = () => {
  return (
    <nav className="nav">
      <ul className="menu">
        <li className="item">
          <a href="#about">About Me</a>
        </li>
        <li className="item">
          <a href="#skills">Skills</a>
        </li>
        <li className="item">
          <a href="#projects">Projects</a>
        </li>
        {/* <li className="item">
          <a href="#contact">Contact</a>
        </li> */}
        {/* <li className="item">
          <a href="jason-francki-resume.pdf" download>
            Resumé
          </a>
        </li> */}
      </ul>
    </nav>
  )
}

export default Nav
