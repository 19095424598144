import About from './About'
import './App.css'
import Contact from './Contact'
import Footer from './Footer'
import Nav from './Nav'
import Projects from './Projects'
import Skills from './Skills'

function App() {
  return (
    <div className='App'>
      <div className='container'>
        <Nav />
        <About />
        <Skills />
        <Projects />
        {/* <Contact /> */}
        <Footer />
      </div>
    </div>
  )
}

export default App
