import './About.css'

const About = () => {
  return (
    <div className="About" id="about">
      <h1>Hello World!</h1>
      <p>
        I'm <span className="intro">Jason Francki</span> - a Web Developer and
        Senior IT Support Analyst with over a decade of professional IT
        experience. I'm a fast and eager learner, skilled problem solver, and
        great communicator that's always looking to grow with a new challenge.
      </p>
    </div>
  )
}

export default About
